<template>
  <div>
    <v-container >
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            2. Порошковый огнетушитель (ОП)
          </v-card-title>
        </v-col>
        <v-col cols="12">
          <v-alert
            border="bottom"
            colored-border
            color="red"
            class="title font-weight-light pl-10"
            elevation="2"
          >
          <div class="d-flex flex-column">
             <span class="mb-1"><strong>Огнетушащим веществом являются порошковые составы</strong></span>
              <p><strong>Принцип действия: </strong>Механизм тушения основан на разбавлении горючей среды газообразными продуктами разложения порошка и охлаждении зон горения.</p>
          </div>

          </v-alert>
        </v-col>
        <v-col cols="6">
          <v-tabs v-model="tab1" active-class="ptm-act-tab-class" color="red" grow background-color="grey lighten-4">
            <v-tab>Схема огнетушителя</v-tab>
            <v-tab>3D модель огнетушителя</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab1">

            <v-tab-item>
              <v-card flat class="ma-5 ptm-img-relative">
                <v-img
                  lazy-src="img/material/exp-1-1.png"
                  max-width="100%"
                  src="img/material/exp-1-1.png"
                  class="ma-1"
                ></v-img>
                <v-tooltip left color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-op-1 title pa-5 red">1</v-btn>
                  </template>
                  <span class="pa-5 pl-0 subtitle-1">Крышка с запорно-пусковым<br> устройством</span>
                </v-tooltip>
                <v-tooltip left color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-op-2 title pa-5 red">2</v-btn>
                  </template>
                  <span class="pa-5 subtitle-1">Стальной корпус</span>
                </v-tooltip>
                <v-tooltip left color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-op-3 title pa-5 red">3</v-btn>
                  </template>
                  <span class="pa-5 pl-0 subtitle-1">Баллон с рабочим газом<br> или газогенератор</span>
                </v-tooltip>
                <v-tooltip left color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-op-4 title pa-5 red">4</v-btn>
                  </template>
                  <span class="pa-5 subtitle-1">Сифонная трубка</span>
                </v-tooltip>
                <v-tooltip right color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-op-5 title pa-5 red">5</v-btn>
                  </template>
                  <span class="pa-5 subtitle-1">Шланг</span>
                </v-tooltip>
                <v-tooltip right color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-op-6 title pa-5 red">6</v-btn>
                  </template>
                  <span class="pa-5 subtitle-1">Заряд (порошок)</span>
                </v-tooltip>
                <v-tooltip right color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-op-7 title pa-5 red">7</v-btn>
                  </template>
                  <span class="pa-5 subtitle-1">Ствол-насадок</span>
                </v-tooltip>
                <v-tooltip right color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-op-8 title pa-5 red">8</v-btn>
                  </template>
                  <span class="pa-5 subtitle-1">Трубка для подвода рабочего газа</span>
                </v-tooltip>

              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="ma-5">
                <model-viewer
                  style="height:400px;width:100%; outline:red;"
                  src="img/3d/op.gltf" alt="A 3D model of an astronaut"
                  camera-orbit="390deg 80deg 30m"
                  camera-target="0m 6m 0m"
                  camera-controls>
                </model-viewer>
                  <v-sheet flat class="d-flex justify-center mx-auto pb-0 mt-2">
                  <v-card class="d-flex justify-center" flat>
                    <v-icon color="red" size="40">mdi-cursor-default-gesture-outline</v-icon>
                    <v-card-title class="font-weight-light">
                      Вращайте модель при помощи мыши
                    </v-card-title>
                  </v-card>
                </v-sheet>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </v-col>
        <v-col cols="6">
          <v-card class="mb-4">
            <v-tabs color="red" grow v-model="tab2">
              <v-tab>Назначение</v-tab>
              <v-tab>Правила обращения</v-tab>
            </v-tabs>
          </v-card>
          <v-card class="pa-2">
            <v-tabs-items v-model="tab2">
              <v-tab-item>
                <v-card-title class="">
                Порошковые огнетушители универсальны<br> и предназначены для тушения:
                </v-card-title>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  пожаров классов А, В, С;
                </v-card-text>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  электроустановок под напряжением до 1 000 В;
                </v-card-text>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  жилых помещений, общественных и промышленных сооружений, транспорта и других объектов;
                </v-card-text>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  загораний бензина, дизельного топлива, лаков, красок и других ГЖ.
                </v-card-text>
                <v-card-title >
                Порошковые огнетушители способны работать при температурах воздуха от -40 до +500 °С.
              </v-card-title>
              </v-tab-item>
              <v-tab-item>
                <v-card class="pa-5">

                <v-card
                  class="pa-2 pl-5 d-flex justify-center align-center"
                  full-width
                  flat

                  >
                    <v-img
                      lazy-src="img/material/loupe.svg"
                      max-width="80"
                      src="img/material/loupe.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      Проверять каждый год
                    </v-card-text>
                  </v-card>
                <v-card
                  class="pa-2 pl-5 d-flex justify-center align-center"
                  full-width
                  flat

                  >
                    <v-img
                      lazy-src="img/material/glove.svg"
                      max-width="80"
                      src="img/material/glove.svg"
                      class="mb-0 pb-0"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      Не касаться металлической части раструба, надевать перчатки во избежание обмораживания
                    </v-card-text>
                  </v-card>
                <v-card
                  class="pa-2 pl-5 d-flex justify-center align-center"
                  full-width
                  flat

                  >
                    <v-img
                      lazy-src="img/material/flash.svg"
                      max-width="80"
                      src="img/material/flash.svg"
                      class="mb-0 pb-0"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      При тушении электроустановок под напряжением, не подводить раструб ближе 1 м
                    </v-card-text>
                  </v-card>
                <v-card
                  class="pa-2 pl-5 d-flex justify-center align-center"
                  full-width
                  flat

                  >
                    <v-img
                      lazy-src="img/material/fire-extinguisher.svg"
                      max-width="80"
                      src="img/material/fire-extinguisher.svg"
                      class="mb-0 pb-0"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      Перезаряжать раз в 5 лет
                    </v-card-text>
                  </v-card>

                </v-card>

              </v-tab-item>
            </v-tabs-items>
          </v-card>

        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>

  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab1: null,
    tab2: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less">
.ptm-act-tab-class {
  background-color: #FFCDD2;
  }
.ptm-img-relative {
  position: relative;
}
.ptm-op-1 {
  position: absolute;
  top: 4%;
  left: 10%;
}
.ptm-op-2 {
  position: absolute;
  top: 32%;
  left: 10%;
}
.ptm-op-3 {
  position: absolute;
  top: 57%;
  left: 10%;
}
.ptm-op-4 {
  position: absolute;
  top: 77%;
  left: 10%;
}
.ptm-op-5 {
  position: absolute;
  top: 8%;
  left: 80%;
}
.ptm-op-6 {
  position: absolute;
  top: 42%;
  left: 80%;
}
.ptm-op-7 {
  position: absolute;
  top: 60%;
  left: 80%;
}
.ptm-op-8 {
  position: absolute;
  top: 77%;
  left: 80%;
}
</style>
